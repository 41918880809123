import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import Container from '../Container';
import { Heading3, HeadingMinor } from '../Headings';

// #region styles
const CDecoration = css`
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 13px;
    background-color: ${COLORS.PRIMARY};
  }

  &:before {
    left: 0;
  }

  &:after {
    display: none;
    right: 0;

    ${mq.tablet} {
      display: block;
    }
  }
`;

const SApplication = styled.section`
  position: relative;
  margin-bottom: 40px;
  background: ${({ background }) => background || 'transparent'};

  ${mq.tablet} {
    display: flex;
    justify-content: space-between;
    margin: 80px 0;
  }
`;

const SGraphic = styled.div`
  text-align: center;
  padding-top: ${({ withPadding }) => (withPadding ? '80px' : 0)};

  ${mq.tablet} {
    order: ${({ reversed }) => (reversed ? -1 : 1)};
    flex-shrink: 0;
    align-self: center;
    max-width: 42%;
    margin-${({ reversed }) => (reversed ? 'right' : 'left')}: 60px;
  }

  ${mq.desktop} {
    margin-${({ reversed }) => (reversed ? 'right' : 'left')}: 100px;
  }

  ${({ imageModifier }) =>
    imageModifier === 'cut-from-right'
      ? `
    text-align: right;
    ${mq.tablet} {
      padding-top: 0;
    }
  `
      : ``}
`;

const SImage = styled.img`
  width: 100%;

  ${mq.tablet} {
    width: auto;
  }

  ${({ imageModifier }) =>
    imageModifier === 'cut-from-right'
      ? `
    width: 150%;
    max-width: 600px;
    max-height: 218px;
    transform: translateX(8%);

    ${mq.tablet} {
      width: 100%;
      transform: none;
    }
  `
      : ``}
`;

const SContent = styled.div`
  padding: 40px 30px;

  ${mq.tablet} {
    padding-top: 60px;
    padding-bottom: 60px;

    ${({ reversed }) =>
      reversed
        ? `
      padding-right: 60px;
      padding-left: 0;
    `
        : `
      padding-right: 0;
      padding-left: 60px;
    `}
  }

  ${mq.landscape} {
    ${({ reversed }) =>
      reversed
        ? `
      padding-right: 90px;
    `
        : `
      padding-left: 90px;
    `}
  }

  ${mq.desktop} {
    ${({ reversed }) =>
      reversed
        ? `
      padding-right: 180px;
    `
        : `
      padding-left: 180px;
    `}
  }
`;

const SHeader = styled.p`
  margin-top: 20px;
  color: ${COLORS.PRIMARY};
  font-size: 16px;
  font-weight: 600;
  font-style: italic;

  ${mq.desktop} {
    font-size: 20px;
  }
`;

const SDescription = styled.div`
  p {
    margin-top: 20px;
  }

  ul {
    padding: 0 0 0 30px;
    margin: 0;

    ${mq.tablet} {
      max-width: 460px;
    }

    ${mq.desktop} {
      max-width: 515px;
    }
  }

  li {
    position: relative;
    padding: 0 0 0 20px;
    margin: 1em 0 0 0;
    list-style-type: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.5em;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${COLORS.STRONG};
    }
  }
`;

const SAppButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  ${mq.tablet} {
    flex-direction: row;
  }
`;

const SAppStore = styled.div`
  width: 120px;
  height: 40px;
  background-image: url('/images/app-store-badge.svg');
  background-size: cover;
`;

const SGooglePlay = styled.div`
  width: 131px;
  height: 40px;
  margin-top: 20px;
  background-image: url('/images/google-play-badge2.png');
  background-size: cover;

  ${mq.tablet} {
    margin-top: 0;
    margin-left: 20px;
  }
`;

// endregion

const Application = (props) => {
  const {
    number,
    title,
    description,
    header,
    imageUrl,
    imageAlt,
    imageModifier,
    background,
    decoration,
    withPadding,
    appStore,
    googlePlay,
    reversed,
  } = props;

  return (
    <Container>
      <SApplication
        background={background}
        css={decoration ? CDecoration : null}
      >
        <SGraphic
          withPadding={withPadding}
          imageModifier={imageModifier}
          reversed={reversed}
        >
          {imageUrl && (
            <SImage
              src={imageUrl}
              alt={imageAlt}
              imageModifier={imageModifier}
            />
          )}
        </SGraphic>
        <SContent reversed={reversed}>
          <HeadingMinor>{number}</HeadingMinor>
          {title && <Heading3>{title}</Heading3>}
          {header && <SHeader>{header}</SHeader>}
          {description && (
            <SDescription dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {(appStore || googlePlay) && (
            <SAppButtons>
              {appStore && (
                <OutboundLink href={appStore} target="_blank">
                  <SAppStore />
                </OutboundLink>
              )}

              {googlePlay && (
                <OutboundLink href={googlePlay} target="_blank">
                  <SGooglePlay />
                </OutboundLink>
              )}
            </SAppButtons>
          )}
        </SContent>
      </SApplication>
    </Container>
  );
};

export default Application;
