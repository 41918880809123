import React from 'react';
import styled from '@emotion/styled';
import { Heading3 } from '../Headings';
import Container from '../Container';
import Button from '../Button';
import Feature from './Feature';
import { mq } from '../../styles/functions/mq';

// #region styles
const SInfrastructure = styled.section`
  padding: 40px 30px 80px;

  ${mq.tablet} {
    padding: 120px 60px;
  }

  ${mq.landscape} {
    padding: 120px 120px;
  }

  ${mq.desktop} {
    padding: 120px 180px;
  }
`;

const SFeatures = styled.div`
  ${mq.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SFeature = styled(Feature)`
  margin-top: 40px;

  ${mq.tablet} {
    width: calc(50% - 35px);

    &:nth-of-type(odd) {
      margin-right: 70px;
    }
  }
`;

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;

  ${mq.tablet} {
    justify-content: flex-start;
  }
`;
// endregion

const Infrastructure = ({ title, features, button }) => (
  <Container>
    <SInfrastructure>
      {title && <Heading3>{title}</Heading3>}

      <SFeatures>
        {features.map((item, index) => (
          <SFeature
            key={index}
            icon={item.icon?.publicURL}
            iconAlt={item.iconAlt}
            title={item.title}
            description={item.description}
          />
        ))}
      </SFeatures>
      {button.text && (
        <SButtonWrapper>
          <Button onClick={button.onClick}>{button.text}</Button>
        </SButtonWrapper>
      )}
    </SInfrastructure>
  </Container>
);

export default Infrastructure;
