import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import { Heading3, HeadingMinor } from '../Headings';
import Background from '../Background';
import Container from '../Container';

// #region styles
const SApplicationsIntro = styled.section`
  position: relative;
  padding: 40px 30px 80px;
  background: ${COLORS.LIGHT};

  ${mq.tablet} {
    padding: 80px 60px;
  }

  ${mq.landscape} {
    padding: 80px 120px;
  }

  ${mq.desktop} {
    padding: 120px 180px;
  }
`;

const SDecoration = styled(Background)`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    right: 8%;
    top: 0;
    width: 60px;
    height: 200px;
    background-size: contain;
    background-position: center top;
  }

  ${mq.desktop} {
    width: 80px;
    height: 250px;
  }
`;

const SHeading3 = styled(Heading3)`
  ${mq.tablet} {
    max-width: 80%;
  }
`;
// endregion

const ApplicationsIntro = ({ title }) => (
  <Container>
    <SApplicationsIntro>
      <SDecoration src="/images/data_pattern_4.png" />
      <HeadingMinor>Applications</HeadingMinor>
      <SHeading3>{title}</SHeading3>
    </SApplicationsIntro>
  </Container>
);

export default ApplicationsIntro;
