import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import Container from '../Container';
import { Heading3 } from '../Headings';

// #region styles
const SApplicationLitmusML = styled.section`
  padding: 80px 30px;
  background: ${COLORS.LIGHT};

  ${mq.tablet} {
    display: flex;
    padding: 80px 60px 100px;
  }

  ${mq.landscape} {
    padding: 80px 120px 100px;
  }
`;

const SDecoration = styled.img`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    right: 5%;
    top: 0;
    width: 70px;
    transform: translateY(-55%);
  }
`;

const SGraphic = styled.div`
  text-align: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.tablet} {
    margin-right: 60px;
  }

  ${mq.landscape} {
    margin-right: 120px;
  }
`;

const SImage = styled.img`
  max-height: 225px;

  ${mq.tablet} {
    max-height: 270px;
  }

  ${mq.desktop} {
    max-height: 330px;
  }
`;

const SContent = styled.div`
  margin-top: 40px;

  ${mq.tablet} {
    margin-top: 0;
    max-width: 480px;
  }

  ${mq.desktop} {
    max-width: 700px;
  }
`;

const SHeader = styled.p`
  margin-top: 20px;
  color: ${COLORS.PRIMARY};
  font-size: 16px;
  font-weight: 600;
  font-style: italic;

  ${mq.desktop} {
    font-size: 20px;
  }
`;

const SDescription = styled.div`
  p {
    margin-top: 20px;
  }

  ul {
    padding: 0 0 0 30px;
    margin: 0;

    ${mq.tablet} {
      max-width: 460px;
    }

    ${mq.desktop} {
      max-width: 515px;
    }
  }

  li {
    position: relative;
    padding: 0 0 0 20px;
    margin: 1em 0 0 0;
    list-style-type: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.5em;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${COLORS.STRONG};
    }
  }
`;
// endregion

const ApplicationLitmusML = (props) => {
  const { title, description, header, imageUrl, imageAlt, background } = props;

  return (
    <Container>
      <SApplicationLitmusML background={background}>
        <SDecoration src="../images/data_white.png" alt="" />
        {imageUrl && (
          <SGraphic>
            <SImage src={imageUrl} alt={imageAlt} />
          </SGraphic>
        )}
        <SContent>
          {title && <Heading3>{title}</Heading3>}
          {header && <SHeader>{header}</SHeader>}
          {description && (
            <SDescription dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </SContent>
      </SApplicationLitmusML>
    </Container>
  );
};

export default ApplicationLitmusML;
