import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Layout from '../components/Layout';
import GetInTouch from '../components/GetInTouch';
import Hero from '../components/Hero';
import Infrastructure from '../components/technology/Infrastructure';
import Application from '../components/technology/Application';
import ApplicationLitmusML from '../components/technology/ApplicationLitmusML';
import DoublePromo from '../components/technology/DoublePromo';
import Container from '../components/Container';
import ApplicationsIntro from '../components/technology/ApplicationsIntro';
import { COLORS } from '../styles/settings/global';
import DownloadModal from '../components/DownloadModal';

const applicationLayouts = {
  '01': {
    hasNumber: true,
    Component: Application,
    props: {
      decoration: true,
      reversed: false,
    },
  },

  '02': {
    hasNumber: true,
    Component: Application,
    props: {
      imageModifier: 'cut-from-right',
      background: COLORS.LIGHT,
      withPadding: true,
    },
  },

  '03': {
    hasNumber: true,
    Component: Application,
    props: {
      decoration: false,
      reversed: true,
    },
  },

  ml: {
    hasNumber: false,
    Component: ApplicationLitmusML,
  },
};

const ApplicationsPage = ({ data }) => {
  const [modal, setModal] = useState(() => ({
    active: false,
    category: '',
    title: '',
    description: '',
    url: '',
  }));
  const { meta, hero, features, applications, imageDivider, promos } =
    data.technologyYaml;
  const getInTouch = data.getInTouchYaml;

  const applicationsNodes = useMemo(
    () =>
      applications.applications.map((application, index) => {
        const layoutDetails = applicationLayouts[application.layout];
        if (!layoutDetails) return null;
        const { Component, hasNumber, props } = layoutDetails;

        return (
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            number={hasNumber ? String(index + 1).padStart(2, '0') : undefined}
            key={application.title}
            title={application.title}
            header={application.header}
            description={application.description?.html}
            imageUrl={application.image?.publicURL}
            imageAlt={application.imageAlt}
            appStore={application.iosUrl}
            googlePlay={application.androidUrl}
          />
        );
      }),
    [applications],
  );

  return (
    <Layout meta={meta}>
      <DownloadModal
        active={modal.active}
        category={modal.category}
        title={modal.title}
        description={modal.description}
        url={modal.url}
        data={data}
        onClose={() => {
          setModal({ active: false });
        }}
      />

      <Hero
        title={hero.title}
        description={hero.description}
        image={hero.image?.publicURL}
        imageAlt={hero.imageAlt}
        decorationImage={hero.decorationImage?.publicURL}
        decorationImageAlt={hero.decorationImageAlt}
      />

      <Infrastructure
        title={features.title}
        features={features.features}
        button={{
          text: features.button.text,
          onClick: () => {
            setModal({
              active: true,
              category: features.button.modalDescription,
              description: features.button.modalDescription,
              url: features.button.file?.publicURL,
            });
            trackCustomEvent({
              category: 'publication',
              action: 'request',
              label: features.button.modalDescription,
            });
          },
        }}
      />

      <ApplicationsIntro title={applications.title} />

      {applicationsNodes}

      {imageDivider.image?.publicURL && (
        <Container>
          <img src={imageDivider.image.publicURL} alt={imageDivider.imageAlt} />
        </Container>
      )}

      <DoublePromo
        promos={promos.promos}
        note={promos.note}
        button={promos.button}
      />

      <GetInTouch
        title={getInTouch.title}
        buttons={getInTouch.buttons}
        location="technology"
      />
    </Layout>
  );
};

export default ApplicationsPage;

// #region graphql
export const pageQuery = graphql`
  query ApplicationsPage {
    technologyYaml {
      meta {
        title
        description
      }
      hero {
        title
        description
        image {
          publicURL
        }
        imageAlt
        decorationImage {
          publicURL
        }
        decorationImageAlt
      }
      features {
        title
        features {
          title
          icon {
            publicURL
          }
          iconAlt
          description
        }
        button {
          text
          file {
            publicURL
          }
          modalDescription
        }
      }
      applications {
        title
        applications {
          layout
          title
          image {
            publicURL
          }
          imageAlt
          header
          description {
            html
          }
          androidUrl
          iosUrl
        }
      }
      imageDivider {
        image {
          publicURL
        }
        imageAlt
      }
      promos {
        promos {
          title
          description
        }
        note
        button {
          text
          newTab
          url
        }
      }
    }

    getInTouchYaml {
      title
      buttons {
        text
        url
        newTab
      }
    }
  }
`;
// endregion
