import React from 'react';
import styled from '@emotion/styled';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Heading3, Heading4 } from '../Headings';
import Container from '../Container';
import Button from '../Button';
import { mq } from '../../styles/functions/mq';
import SmartLink from '../SmartLink';

// #region styles
const SDoublePromo = styled.section`
  padding: 40px 30px 80px;

  ${mq.tablet} {
    padding-left: 60px;
    padding-right: 60px;
  }

  ${mq.landscape} {
    padding-left: 90px;
    padding-right: 90px;
  }

  ${mq.desktop} {
    padding-left: 180px;
    padding-right: 180px;
  }
`;

const SPromos = styled.div`
  ${mq.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const SPromo = styled.article`
  margin-top: 40px;

  ${mq.tablet} {
    width: calc(50% - 35px);
  }

  ${mq.landscape} {
    width: calc(50% - 40px);
  }

  ${mq.desktop} {
    width: calc(50% - 60px);
  }
`;

const SHeading3 = styled(Heading3)`
  ${mq.tablet} {
    min-height: 2.7em;
  }
`;

const SDescription = styled.div`
  h3 + & {
    margin-top: 20px;
  }
`;

const SSummary = styled.div`
  margin-top: 40px;
  text-align: center;

  ${mq.tablet} {
    margin-top: 50px;
  }
`;

const SHeading4 = styled(Heading4)`
  ${mq.tablet} {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SButton = styled(Button)`
  margin-top: 40px;
`;
// endregion

const DoublePromo = ({ promos, note, button }) => (
  <Container>
    <SDoublePromo>
      <SPromos>
        {promos.map(({ title, description }, index) => (
          <SPromo key={index}>
            {title && <SHeading3>{title}</SHeading3>}
            {description && <SDescription>{description}</SDescription>}
          </SPromo>
        ))}
      </SPromos>
      <SSummary>
        <SHeading4>{note}</SHeading4>
        <SmartLink {...button}>
          <SButton
            onClick={() => {
              trackCustomEvent({
                category: 'contact interest',
                action: 'technology',
                label: `${button.text.toLowerCase()} cta`,
              });
            }}
          >
            {button.text}
          </SButton>
        </SmartLink>
      </SSummary>
    </SDoublePromo>
  </Container>
);

export default DoublePromo;
