import React from 'react';
import styled from '@emotion/styled';
import { Heading4 } from '../Headings';

// #region styles
const SFeature = styled.div``;

const SHeading = styled.div`
  display: flex;
  align-items: center;
`;

const SHeading4 = styled(Heading4)``;

const SIcon = styled.img`
  display: block;
  width: 48px;
  height: 48px;
  margin-right: 15px;
  object-fit: contain;
`;

const SDescription = styled.div`
  margin-top: 20px;
  white-space: pre-line;
`;
// endregion

const Infrastructure = ({ icon, iconAlt, title, description, className }) => (
  <SFeature className={className}>
    <SHeading>
      {icon && <SIcon src={icon} alt={iconAlt} />}
      {title && <SHeading4>{title}</SHeading4>}
    </SHeading>
    {description && <SDescription>{description}</SDescription>}
  </SFeature>
);

export default Infrastructure;
